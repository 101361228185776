import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/11.png"
import Antonin from "../images/photos/antonin.jpg"

const LECTURER = [
  {
    src: Antonin,
    alt: "",
    personName: "Antonín Kučera",
    text: [
      "Tonda vystudoval na VŠE Informační management. Po škole se nějakou dobu věnoval datům a on-line marketingu na volné voze.",
      "V roce 2015 nastoupil do Livesportu jako webový analytik a dnes tam vede celé Business Intelligence oddělení, které za necelých 7 let vybudoval. Nejvíc ho baví spojení dat a businessu, především evangelizace a aplikace dat v praxi.",
    ],
    linkedInProfile: "//linkedin.com/in/antoninkucera",
  },
]

const ZavadeniDatoveKultury: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper
        title="Zavádění datové kultury do firmy, vytváření mostů mezi analytiky a dalšími odděleními"
        ogImage={OgImage}
      />

      <SyllabusHeader
        orangeText="Zavádění datové kultury do firmy,"
        blackText="vytváření mostů mezi analytiky a dalšími odděleními"
        numberOfLesson="11/13"
      ></SyllabusHeader>

      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>
          datová kultura
          <div>co to je</div>
          <div>jaký to má význam</div>
          <div>trendy</div>
        </div>

        <div>
          datově informovaná kultura
          <div>jak se liší od data driven</div>
          <div>
            datová demokracie
            <div>co to znamená</div>
            <div>jak ji implementovat</div>
          </div>
        </div>

        <div>
          základní pilíře datové demokratizace
          <div>
            přístupnost k datům (otevřená data)
            <div>co to přesně znamená</div>
            <div>
              jak být v souladu s firmou
              <div>vize / strategie / KPIs…</div>
              <div>Measurement model</div>
            </div>
            <div>
              co je potřeba k tomu mít a jaké jsou postupy / metody na otevření
              dat
              <div>Meta data, governance, data lineage</div>
            </div>
            <div>
              budování interního analytického týmu
              <div>
                typy analytických týmů
                <div>výhody / nevýhody</div>
              </div>
              <div>
                analytický / datový tým z 0<div>co to obnáší</div>
                <div>na co si dát pozor</div>
                <div>…</div>
              </div>
            </div>
          </div>
          <div>
            nástroje
            <div>
              data fabric vs. data mesh
              <div>výhody a kdy se co hodí</div>
            </div>
            <div>co nezbytně potřebujete ve firmě mít</div>
            <div>co zvážit při výběru</div>
            <div>tipy na zajímavé nástroje</div>
          </div>
          <div>srozumitelná data pro lidi (vzdělávání a datová gramotnost)</div>
          <div>
            program datové gramotnosti
            <div>kdo by v něm měl být</div>
            <div>
              jak ho připravit a implementovat (+ překážky / příležitosti)
            </div>
            <div>co všechno zvážit</div>
          </div>
          <div>
            změna mindsetu
            <div>
              jak u lidí změnit mindset
              <div>přemýšlení o datech / analýzách</div>
              <div>větší zapojení dat v rámci jejich práce</div>
            </div>
            <div>
              Bottom-up přístup
              <div>co může ve firmě přinést</div>
            </div>
          </div>
        </div>

        <div>
          dopady datově-informované kultury v praxi
          <div>pár příkladů, co může přinést</div>
        </div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default ZavadeniDatoveKultury
